var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"header"},[_c('v-avatar',{attrs:{"tile":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_c('span',[_vm._v("Editace tiketu: "+_vm._s(_vm.ticket.Title))])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Datum vytvoření: "+_vm._s(_vm._f("formatDate")(_vm.ticket.CreatedOn))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Vytvořil: "+_vm._s(_vm.ticket.Author)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Společnost: "+_vm._s(_vm.ticket.ContactName)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.ticket.AvailableStates.Solver.length)?_c('div',[_c('v-select',{attrs:{"items":_vm.ticket.AvailableStates.Solver,"item-value":"Name","item-text":"Name","label":"Řešitel"},model:{value:(_vm.ticket.Solver),callback:function ($$v) {_vm.$set(_vm.ticket, "Solver", $$v)},expression:"ticket.Solver"}})],1):_c('div',[_vm._v("Řešitel: "+_vm._s(_vm.ticket.Solver))])]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.ticket.AvailableStates.Project.length)?_c('div',[_c('v-select',{attrs:{"items":_vm.ticket.AvailableStates.Project,"item-value":"Name","item-text":"Name","label":"Projekt"},model:{value:(_vm.ticket.Project),callback:function ($$v) {_vm.$set(_vm.ticket, "Project", $$v)},expression:"ticket.Project"}})],1):_c('div',[_vm._v("Projekt: "+_vm._s(_vm.ticket.Project))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.ticket.AvailableStates.Status.length)?_c('div',[_c('v-select',{attrs:{"items":_vm.ticket.AvailableStates.Status,"item-value":"Name","item-text":"Name","label":"Status"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('status.' + item.Name)))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('status.' + item.Name)))])]}}],null,false,2320350780),model:{value:(_vm.ticket.Status),callback:function ($$v) {_vm.$set(_vm.ticket, "Status", $$v)},expression:"ticket.Status"}})],1):_c('div',[_vm._v("Status: "+_vm._s(_vm.ticket.Status))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.ticket.AvailableStates.Priority.length)?_c('div',[_c('v-select',{attrs:{"items":_vm.ticket.AvailableStates.Priority,"item-value":"Name","item-text":"Name","label":"Priorita"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('priority.' + item.Name)))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('priority.' + item.Name)))])]}}],null,false,295918012),model:{value:(_vm.ticket.Priority),callback:function ($$v) {_vm.$set(_vm.ticket, "Priority", $$v)},expression:"ticket.Priority"}})],1):_c('div',[_vm._v("Priorita: "+_vm._s(_vm.ticket.Priority))])])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"#e7501e","depressed":"","small":"","dark":""},on:{"click":_vm.save}},[_vm._v("Uložit změny")]),_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":_vm.cancel}},[_vm._v("Zpět")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }