









































import { Component, Vue } from 'vue-property-decorator';
import { router } from '../../helpers';
import { UserDetail } from '../../models/user-detail.model';

@Component
export default class UserCreateComponent extends Vue {
  account: any = this.$store.state.account;
  application: any = this.$store.state.application;
  userDetail: UserDetail = new UserDetail();
  emailRules = [(v: string) => !!v || 'Pole je povinné', (v: string) => /.+@.+\..+/.test(v) || 'Uživatelské jméno musí být email'];

  created() {}

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  submit() {
    (this.$refs.userDetail as any).validate();
    //this.userDetail.customValidate();

    if (this.userDetail.valid) {
      const form = {
        FirstName: this.userDetail.firstName.value,
        LastName: this.userDetail.lastName.value,
        UserName: this.userDetail.userName.value,
        Password: this.userDetail.password.value,
        IsExternal: this.userDetail.isExternal
      };

      (this as any).$store.dispatch('users/create', { form });
    }
  }

  handleCancelSubmit() {
    router.push(`/users`);
  }

  get breadcrumbs() {
    return [
      {
        text: 'Uživatelé',
        disabled: false,
        href: '/users'
      }
    ];
  }
}
