import { BaseProperty } from './baseProperty.model';
export class AccountDetail {
  public oldPassword: BaseProperty = new BaseProperty();
  public newPassword: BaseProperty = new BaseProperty();
  public confirmPassword: BaseProperty = new BaseProperty();
  public valid: boolean = true;

  public customValidate() {
    this.oldPassword.errorMessage = '';
    this.newPassword.errorMessage = '';
    this.confirmPassword.errorMessage = '';

    if (this.newPassword.value !== this.confirmPassword.value) {
      this.valid = false;
      this.confirmPassword.errorMessage = 'Zadaná hesla nejsou stejná.';
    }
  }

  public resetCustomErrors(): void {
    this.oldPassword.errorMessage = '';
    this.newPassword.errorMessage = '';
    this.confirmPassword.errorMessage = '';
  }
}
