import { WorkRecordSearch } from '@/models';
import { workRecordsService } from '@/services';

const state = {
  loading: false,
  items: [],
  detail: null,
  summary: null,
  workTypes: {
    items: [],
    totalCount: 0
  },
  search: {
    list: new WorkRecordSearch(),
    user: new WorkRecordSearch(),
    project: new WorkRecordSearch(),
    ticket: new WorkRecordSearch()
  }
};

const actions = {
  getAll({ commit, dispatch }: any, { searchQuery, searchType }: any) {
    commit('getRequest');
    commit('saveSearchQuery', { searchQuery, searchType });

    workRecordsService
      .getAll(searchQuery)
      .then(
        records => commit('getAllSuccess', records),
        error => commit('getFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  createWorkRecord({ dispatch, commit }: any, { newWorkRecord, searchType }: any) {
    dispatch('application/setLoading', true, { root: true });

    workRecordsService
      .createWorkRecord(newWorkRecord)
      .then(
        () => {
          commit('createWorkSuccess');
          dispatch('alert/success', `Záznam práce byl vytvořen.`, {
            root: true
          });
          dispatch('getAll', { searchQuery: (state.search as any)[searchType], searchType });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Záznam práce se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  updateWorkRecord({ dispatch, commit }: any, { newWorkRecord, searchType }: any) {
    dispatch('application/setLoading', true, { root: true });

    workRecordsService
      .updateWorkRecord(newWorkRecord)
      .then(
        () => {
          commit('createWorkSuccess');
          dispatch('alert/success', `Záznam práce byl upraven.`, {
            root: true
          });
          dispatch('getAll', { searchQuery: (state.search as any)[searchType], searchType });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Záznam práce se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  getWorkTypes({ dispatch, commit }: any, { projectId }: any) {
    commit('getRequest');

    workRecordsService
      .getWorkTypes(projectId)
      .then(
        workTypes => commit('getWorkTypesSuccess', workTypes),
        error => commit('getFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getRequest(state: any) {
    state.loading = true;
  },
  saveSearchQuery(state: any, { searchQuery, searchType }: any) {
    state.search[searchType] = searchQuery;
  },
  getAllSuccess(state: any, records: any) {
    state.totalCount = records.totalCount;
    state.items = records.Items;
    state.summary = records.Summary;
  },
  getWorkTypesSuccess(state: any, workTypes: any) {
    state.workTypes.totalCount = workTypes.totalCount;
    state.workTypes.items = workTypes;
  },
  getFailure(state: any, error: any) {
    state = { error };
  },
  createWorkSuccess(state: any) {}
};

export const workRecords = {
  namespaced: true,
  state,
  actions,
  mutations
};
