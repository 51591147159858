












































































































import { Component, Vue } from 'vue-property-decorator';
import { AccountDetail } from '../../models/account-detail.model';

@Component({})
export default class AccountDetailComponent extends Vue {
  account: any = this.$store.state.account;
  form: AccountDetail = new AccountDetail();
  showPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmationPassword: boolean = false;

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  resetCustomErrors(): void {
    this.form.oldPassword.errorMessage = '';
    this.form.newPassword.errorMessage = '';
    this.form.confirmPassword.errorMessage = '';
  }

  submit(): void {
    (this.$refs.form as any).validate();
    this.form.customValidate();

    if (this.form.valid) {
      const form = this.form;
      (this as any).$store.dispatch('account/passwordChange', { form }).then(() => {
        (this.$refs.form as any).reset();
      });
    }
  }
}
