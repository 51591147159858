import moment from 'moment';

export class WorkRecordsList {
  public headers: any = {
    ticket: [
      { text: 'Uživatel', value: 'User.Name' },
      { text: 'Datum', value: 'Date' },
      { text: 'Čas', value: 'HoursSpent' },
      { text: 'Popis', value: 'Note' },
      { text: 'K fakturaci', value: 'IsBillable' },
      { text: 'Typ', value: 'WorkType.Name' }
    ],
    project: [
      { text: 'Uživatel', value: 'User.Name' },
      { text: 'Datum', value: 'Date' },
      { text: 'Čas', value: 'HoursSpent' },
      { text: 'Popis', value: 'Note' },
      { text: 'K fakturaci', value: 'IsBillable' },
      { text: 'Typ', value: 'WorkType.Name' }
    ],
    user: [
      { text: 'Projekt', value: 'Project' },
      { text: 'Datum', value: 'Date' },
      { text: 'Čas', value: 'HoursSpent' },
      { text: 'Popis', value: 'Note' },
      { text: 'K fakturaci', value: 'IsBillable' },
      { text: 'Typ', value: 'WorkType.Name' }
    ],
    list: [
      { text: 'Uživatel', value: 'User.Name' },
      { text: 'Projekt', value: 'Project' },
      { text: 'Datum', value: 'Date' },
      { text: 'Čas', value: 'HoursSpent' },
      { text: 'Popis', value: 'Note' },
      { text: 'K fakturaci', value: 'IsBillable' },
      { text: 'Typ', value: 'WorkType.Name' }
    ]
  };

  public detail = {
    datePicker: false,
    date: '',
    hoursSpent: null,
    isBillable: true,
    note: '',
    projectId: null,
    userId: null,
    ticketId: null,
    workTypeId: null,
    id: null
  };

  get isValid(): boolean {
    return (
      this.detail.date !== null &&
      this.detail.date !== '' &&
      this.detail.hoursSpent !== null &&
      this.detail.hoursSpent !== '' &&
      this.detail.projectId !== null &&
      this.detail.workTypeId !== null
    );
  }

  get formatedDate(): string {
    if (this.detail.date) {
      return moment(String(this.detail.date)).format('DD.MM.YYYY');
    } else {
      return '';
    }
  }
}

export class WorkRecordSearch {
  pageRows: number = 10;
  actualPage: number = 1;
  orderBy: string = 'Date';
  orderByAsc: boolean = false;
  dates: any[] = [new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)];
  projectId: number | null = null;
  ticketId: number | null = null;
  userId: number | null = null;
}
