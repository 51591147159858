


































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TicketEdit extends Vue {
  @Prop()
  ticket: any;
  @Prop()
  dialog: any;

  save() {
    this.$emit('save', this.ticket);
  }

  cancel() {
    this.$emit('cancel');
  }
}
