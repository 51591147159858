




















import { WorkRecordsList } from '@/models';
import { Component, Vue } from 'vue-property-decorator';
import WorkRecordEdit from '../../components/WorkRecordEdit.vue';
import WorkRecordTable from '../../components/WorkRecordTable.vue';

@Component({
  components: {
    WorkRecordEdit,
    WorkRecordTable
  }
})
export default class WorkRecordListComponent extends Vue {
  projects: any = this.$store.state.projects;
  account: any = this.$store.state.account;
  workRecordsList: WorkRecordsList = new WorkRecordsList();
  showWorkEdit: boolean = false;

  created(): void {
    (this as any).$store.dispatch('projects/getAll');
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  selectWorkRecord(item: any): void {
    this.workRecordsList.detail.id = item.Id;
    this.workRecordsList.detail.date = item.Date.slice(0, 10);
    this.workRecordsList.detail.hoursSpent = item.HoursSpent;
    this.workRecordsList.detail.isBillable = item.IsBillable;
    this.workRecordsList.detail.note = item.Note;
    this.workRecordsList.detail.projectId = item.Project.Id;
    this.workRecordsList.detail.userId = item.User.Id;
    this.workRecordsList.detail.ticketId = item.TicketId;
    this.workRecordsList.detail.workTypeId = item.WorkType.Id;

    this.showWorkEdit = true;
  }

  saveWorkRecord() {
    if (this.workRecordsList.detail.id !== null) {
      const newWorkRecord = this.workRecordsList.detail;
      (this as any).$store.dispatch('workRecords/updateWorkRecord', { newWorkRecord, searchType: 'list' });
    } else {
      const newWorkRecord = this.workRecordsList.detail;
      newWorkRecord.userId = this.account.user.UserId;

      (this as any).$store.dispatch('workRecords/createWorkRecord', { newWorkRecord, searchType: 'list' });
    }

    this.showWorkEdit = false;
    this.clearWorkEditDetail();
  }

  closeWorkRecord() {
    this.showWorkEdit = false;
    this.clearWorkEditDetail();
  }

  clearWorkEditDetail() {
    this.workRecordsList.detail = {
      datePicker: false,
      date: '',
      hoursSpent: null,
      isBillable: true,
      note: '',
      projectId: null,
      userId: null,
      ticketId: null,
      workTypeId: null,
      id: null
    };
  }

  get breadcrumbs() {
    return [
      {
        text: 'Evidence práce',
        disabled: true
      }
    ];
  }
}
