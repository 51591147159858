


















































import { Component, Vue } from 'vue-property-decorator';
import { PasswordNew } from '../../models/password-new.model';
import { recaptchaService } from '../../services';

@Component({})
export default class PasswordNewComponent extends Vue {
  form: PasswordNew = new PasswordNew();
  token: string = '';
  userKey: string = '';
  showPassword: boolean = false;

  created() {
    this.token = this.$route.params.token;
    this.userKey = this.$route.params.userKey;
  }

  updated() {
    this.$recaptchaInstance?.showBadge();
  }

  submit(): void {
    (this.$refs.form as any).validate();
    if (this.form.valid) {
      const form = this.form;
      const resetToken = this.token;
      const userKey = this.userKey;
      this.$recaptcha('passwordNew').then(token => {
        recaptchaService.validate(token).then(response => {
          if (response.success) {
            (this as any).$store.dispatch('account/passwordNew', { form, resetToken, userKey }).then(() => {
              (this.$refs.form as any).reset();
            });
          }
        });
      });
    }
  }
}
