















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { recaptchaService } from '../../services';
import { PasswordReset } from '../../models/password-reset.model';

@Component({})
export default class PasswordResetComponent extends Vue {
  form: PasswordReset = new PasswordReset();

  updated() {
    this.$recaptchaInstance?.showBadge();
  }

  submit(): void {
    (this.$refs.form as any).validate();
    if (this.form.valid) {
      const form = this.form;
      this.$recaptcha('passwordReset').then(token => {
        recaptchaService.validate(token).then(response => {
          if (response.success) {
            (this as any).$store.dispatch('account/passwordReset', { form }).then(() => {
              (this.$refs.form as any).reset();
            });
          }
        });
      });
    }
  }
}
