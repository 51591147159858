var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex grow flex-wrap"},[_c('v-card-title',[_c('v-avatar',{attrs:{"tile":""}},[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"light":""}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" Vytvořit tiket ")],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"id":"ticket-create-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleCreateSubmit($event)}},model:{value:(_vm.ticketDetail.valid),callback:function ($$v) {_vm.$set(_vm.ticketDetail, "valid", $$v)},expression:"ticketDetail.valid"}},[_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-6"},[_c('v-text-field',{attrs:{"label":"Název","name":"title","type":"text","error-messages":_vm.ticketDetail.title.errorMessage,"rules":_vm.ticketDetail.title.requiredRule,"required":"","autofocus":""},model:{value:(_vm.ticketDetail.title.value),callback:function ($$v) {_vm.$set(_vm.ticketDetail.title, "value", $$v)},expression:"ticketDetail.title.value"}})],1),_c('v-col',{staticClass:"col-12 col-md-6"},[_c('v-autocomplete',{attrs:{"items":_vm.projects.items,"item-value":"Id","item-text":"Name","label":"Projekt","rules":_vm.ticketDetail.project.requiredRule,"filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}}]),model:{value:(_vm.ticketDetail.project.value),callback:function ($$v) {_vm.$set(_vm.ticketDetail.project, "value", $$v)},expression:"ticketDetail.project.value"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-6"},[_c('v-checkbox',{attrs:{"item-text":"UsersVisibility","label":"Založit tiket za jiného uživatele","disabled":_vm.projectId === ''},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),(_vm.projects.detail && _vm.projects.detail.Users && _vm.checked)?_c('v-col',{staticClass:"col-12 col-md-6"},[_c('v-select',{attrs:{"items":_vm.projects.detail.Users,"item-value":"Id","item-text":"Name","label":"Uživatel"},model:{value:(_vm.ticketDetail.authorId),callback:function ($$v) {_vm.$set(_vm.ticketDetail, "authorId", $$v)},expression:"ticketDetail.authorId"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Termín","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerDialog),callback:function ($$v) {_vm.datePickerDialog=$$v},expression:"datePickerDialog"}},[_c('v-date-picker',{attrs:{"format":"dd/mm/yyyy"},model:{value:(_vm.ticketDetail.deadline),callback:function ($$v) {_vm.$set(_vm.ticketDetail, "deadline", $$v)},expression:"ticketDetail.deadline"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.ticketDetail.extensions},model:{value:(_vm.ticketDetail.message.value),callback:function ($$v) {_vm.$set(_vm.ticketDetail.message, "value", $$v)},expression:"ticketDetail.message.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.application.loading,"small":"","depressed":"","color":"#e7501e","type":"submit","form":"ticket-create-form","dark":""}},[_vm._v("Vytvořit")]),_c('v-btn',{attrs:{"disabled":_vm.application.loading,"small":"","text":"","color":"secondary"},on:{"click":_vm.handleCancelSubmit}},[_vm._v("Zpět")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }