import Vue from 'vue';
import Router from 'vue-router';

import TicketListComponent from '../pages/ticket/TicketList.vue';
import TicketDetailComponent from '../pages/ticket/TicketDetail.vue';
import TicketCreateComponent from '../pages/ticket/TicketCreate.vue';
import ProjectListComponent from '../pages/project/ProjectList.vue';
import ProjectDetailComponent from '../pages/project/ProjectDetail.vue';
import ProjectCreateComponent from '../pages/project/ProjectCreate.vue';
import LoginComponent from '../pages/login/LoginPage.vue';
import PasswordResetComponent from '../pages/login/PasswordResetPage.vue';
import PasswordNewComponent from '../pages/login/PasswordNewPage.vue';
import AccountDetailComponent from '../pages/account/AccountDetail.vue';
import UserListComponent from '../pages/user/UserList.vue';
import UserDetailComponent from '../pages/user/UserDetail.vue';
import UserCreateComponent from '../pages/user/UserCreate.vue';
import WorkRecordListComponent from '../pages/work-record/List.vue';
import NotFoundPage from '../pages/error/404.vue';
import NotAuthorizedPage from '../pages/error/401.vue';

Vue.use(Router);

function checkAdminRights(to: any, from: any, next: any) {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  if (user?.User.Role === 'Administrator') {
    next();
  } else {
    next('/401');
  }
}

function checkInternal(to: any, from: any, next: any) {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  if (!user?.User.IsExternal || user?.User.Role === 'Administrator') {
    next();
  } else {
    next('/401');
  }
}

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: TicketListComponent },
    { path: '/login', component: LoginComponent },
    { path: '/passwordReset', component: PasswordResetComponent },
    { path: '/newPassword/:token/:userKey', component: PasswordNewComponent },
    { path: '/ticket/:id', component: TicketDetailComponent },
    { path: '/project/:id', component: ProjectDetailComponent, beforeEnter: checkAdminRights },
    { path: '/create-project', component: ProjectCreateComponent, beforeEnter: checkAdminRights },
    { path: '/create', component: TicketCreateComponent },
    { path: '/projects', component: ProjectListComponent, beforeEnter: checkAdminRights },
    { path: '/account', component: AccountDetailComponent },
    { path: '/users', component: UserListComponent, beforeEnter: checkAdminRights },
    { path: '/user/:id', component: UserDetailComponent, beforeEnter: checkAdminRights },
    { path: '/create-user', component: UserCreateComponent, beforeEnter: checkAdminRights },
    { path: '/work-record', component: WorkRecordListComponent, beforeEnter: checkInternal },
    { path: '/404', component: NotFoundPage },
    { path: '/401', component: NotAuthorizedPage },

    // otherwise redirect to home
    { path: '*', redirect: '/404' }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/passwordReset'];
  const publicPagesWithParameters = ['/newPassword'];
  const authRequired = !publicPages.includes(to.path) && !publicPagesWithParameters.find(url => to.path.startsWith(url));
  const loggedIn = localStorage.getItem('user');

  if (authRequired) {
    if (!loggedIn) {
      localStorage.setItem('returnUrl', to.path);
      return next('/login');
    }

    const user = JSON.parse(loggedIn || '');

    if (!user.User) {
      localStorage.setItem('returnUrl', to.path);
      return next('/login');
    }
  }

  next();
});
