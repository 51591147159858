import { router } from '@/helpers';
import { projectService } from '@/services';

const state = {
  loading: false,
  items: [],
  detail: null,
  search: {
    includeClosed: false,
    onlyMine: false,
    pageRows: 10,
    actualPage: 1,
    orderBy: '',
    orderByAsc: false
  }
};

const actions = {
  getAll({ commit }: any, searchQuery: any) {
    commit('getRequest');

    projectService.getAll().then(
      projects => commit('getAllSuccess', projects),
      error => commit('getFailure', error)
    );
  },

  getById({ dispatch, commit }: any, id: any) {
    dispatch('application/setLoading', true, { root: true });

    projectService
      .getById(id)
      .then(
        project => commit('getByIdSuccess', project),
        error => commit('getFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  edit({ dispatch, commit }: any, { form, projectId }: any) {
    dispatch('application/setLoading', true, { root: true });

    projectService
      .edit(form, projectId)
      .then(
        () => {
          commit('editProject', form);
          dispatch('alert/success', `Projekt byl upraven.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Projekt se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  addUser({ dispatch, commit }: any, { projectId, userId }: any) {
    dispatch('application/setLoading', true, { root: true });

    projectService
      .addUser(projectId, userId)
      .then(
        () => {
          commit('addUser', userId);
          dispatch('alert/success', `Uživatel byl přidán.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Uživatele se nepodařilo přidat.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  removeUser({ dispatch, commit }: any, { projectId, userId }: any) {
    dispatch('application/setLoading', true, { root: true });

    projectService
      .removeUser(projectId, userId)
      .then(
        () => {
          commit('removeUser', userId);
          dispatch('alert/success', `Uživatel byl odebrán.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Uživatele se nepodařilo odebrat.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  create({ dispatch, commit }: any, { form }: any) {
    dispatch('application/setLoading', true, { root: true });

    projectService
      .createProject(form)
      .then(
        () => {
          dispatch('alert/success', `Projekt byl vytvořen.`, {
            root: true
          });

          router.push('/projects');
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Projekt se nepodařilo založit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getRequest(state: any) {
    state.loading = true;
  },
  getAllSuccess(state: any, projects: any) {
    state.items = projects;
  },
  getFailure(state: any, error: any) {
    state = { error };
  },
  getByIdSuccess(state: any, project: any) {
    state.detail = project;
  },
  editProject(state: any, project: any) {
    state.detail.Name = project.Name;
    state.detail.ContactId = project.ContactId;
  },
  addUser(state: any, userId: any) {
    state.detail.Users.push({ Id: userId });
  },
  removeUser(state: any, userId: any) {
    state.detail.Users = state.detail.Users.filter((user: any) => user.Id !== userId);
  },
  clearProject(state: any) {
    state.detail = null;
  }
};

export const projects = {
  namespaced: true,
  state,
  actions,
  mutations
};
