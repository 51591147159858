import Vue from 'vue';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'cs-CZ',
  decimalLength: 2,
  autoDecimalMode: false,
  min: 0,
  max: null,
  defaultValue: null,
  valueAsInteger: false,
  allowNegative: false
});

export default VCurrencyField;
