var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.workRecordsList.headers[_vm.searchType],"items":_vm.workRecords.items,"server-items-length":_vm.workRecords.totalCount,"loading":_vm.application.loading,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50], 'disable-pagination': _vm.application.loading },"page":_vm.workRecords.search.actualPage,"items-per-page":_vm.search().pageRows},on:{"update:page":_vm.onPage,"update:sort-by":_vm.onSort,"update:sort-desc":_vm.onSortDesc,"update:items-per-page":_vm.onItemsPerPage,"click:row":_vm.select},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"filterItem",attrs:{"cols":"12","md":_vm.projects ? 4 : 12}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.search().dates,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.$set(_vm.search(), "dates", $event)},"update:return-value":function($event){_vm.$set(_vm.search(), "dates", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"content-edit px-4 py-4"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-calendar")]),_c('span',[_vm._v("Zobrazeno období od "),_c('strong',[_vm._v(_vm._s(_vm._f("formatOnlyDate")(_vm.search().dates[0])))]),_vm._v(" do "),_c('strong',[_vm._v(_vm._s(_vm._f("formatOnlyDate")(_vm.search().dates[1])))])]),_c('div',{staticClass:"content-edit-box"},[_c('span',{staticClass:"edit-icon"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)])],1)]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"#e7501e","range":"","scrollable":""},model:{value:(_vm.search().dates),callback:function ($$v) {_vm.$set(_vm.search(), "dates", $$v)},expression:"search().dates"}},[_c('v-btn',{attrs:{"color":"#e7501e","depressed":"","small":"","dark":""},on:{"click":function($event){return _vm.selectDate()}}},[_vm._v(" Vybrat ")]),_c('v-btn',{attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){_vm.datePicker = false}}},[_vm._v(" Zrušit ")])],1)],1)],1),(_vm.projects)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}}):_vm._e(),(_vm.projects)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-value":"Id","item-text":"Name","label":"Projekt","clearable":"","filter":_vm.customFilter},on:{"change":function($event){return _vm.filterByProject()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}}],null,false,1155120668),model:{value:(_vm.search().projectId),callback:function ($$v) {_vm.$set(_vm.search(), "projectId", $$v)},expression:"search().projectId"}})],1):_vm._e(),(_vm.workRecords.summary)?_c('v-col',{staticClass:"filterItem",attrs:{"cols":"12"}},[_c('div',{staticClass:"px-4"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-clock")]),_vm._v(" Celkem zapsáno "),_c('strong',[_vm._v(_vm._s(_vm.workRecords.summary.TotalHours)+" hodin")]),_vm._v(" a z toho k fakturaci "),_c('strong',[_vm._v(_vm._s(_vm.workRecords.summary.BillableHours)+" hodin")])],1)]):_vm._e()],1)]},proxy:true},{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatOnlyDate")(item.Date))+" ")]}},{key:"item.IsBillable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.IsBillable ? 'Ano' : 'Ne')+" ")]}},{key:"item.Project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Project.Name)+" "),_c('small',[_vm._v("("+_vm._s(item.Contact.Name)+")")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }