const state = {
  type: null,
  message: null,
  timeStamp: null
};

const actions = {
  success({ commit }: any, message: any) {
    commit("success", message);
  },
  error({ commit }: any, message: any) {
    commit("error", message);
  },
  clear({ commit }: any, message: any) {
    commit("success", message);
  }
};

const mutations = {
  success(state: any, message: any) {
    state.type = "success";
    state.message = message;
    state.timeStamp = Date.now();
  },
  error(state: any, message: any) {
    state.type = "error";
    state.message = message;
    state.timeStamp = Date.now();
  },
  clear(state: any) {
    state.type = null;
    state.message = null;
    timeStamp: null;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
