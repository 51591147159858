import { authHeader } from '@/helpers';
import { handleResponse } from './base.service';

export const customDataService = {
  get,
  post,
  edit,
  deleteData
};

async function get(entityId: number, entityName: string): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/CustomData?entityId=${entityId}&entityName=${entityName}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await get(entityId, entityName);
  }
}

async function post(entityId: number, entityName: string, name: string, payload: any): Promise<any> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      EntityId: entityId,
      EntityName: entityName,
      Name: name,
      Payload: payload
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/CustomData`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await post(entityId, entityName, name, payload);
  }
}

async function edit(id: number, entityId: number, entityName: string, name: string, payload: any) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      EntityId: entityId,
      EntityName: entityName,
      Name: name,
      Payload: payload
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/CustomData/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await edit(id, entityId, entityName, name, payload);
  }
}

async function deleteData(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/CustomData/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await deleteData(id);
  }
}
