import { authHeader } from '@/helpers';
import { handleResponse } from './base.service';

export const accountService = {
  login,
  passwordReset,
  logout,
  passwordNew,
  passwordChange,
  refreshToken
};

function login(username: string, password: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${process.env.VUE_APP_API}/api/Auth/login`, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (response.data.AccessToken.Token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
}

function passwordReset(username: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(`${process.env.VUE_APP_API}/api/Accounts/resetPassword/${username}`, requestOptions).then(handleResponse);
}

function passwordNew(password: string, token: string, userKey: string) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      NewPassword: password,
      Token: token,
      UserKey: userKey
    })
  };

  return fetch(`${process.env.VUE_APP_API}/api/Accounts/ConfirmPasswordReset`, requestOptions).then(handleResponse);
}

async function passwordChange(form: any, userName: string) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      NewPassword: form.newPassword.value,
      OldPassword: form.oldPassword.value
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Accounts/ChangePassword`, requestOptions);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await passwordChange(form, userName);
  }
}

function logout() {
  localStorage.removeItem('user');
}

async function refreshToken() {
  const user = JSON.parse(localStorage.getItem('user') || 'null');

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      AccessToken: user.AccessToken.Token,
      RefreshToken: user.RefreshToken
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Auth/refreshtoken`, requestOptions);
  if (response.ok) {
    const text = await response.text();
    const data = JSON.parse(text);

    if (data.AccessToken) {
      user.AccessToken = data.AccessToken;
      user.RefreshToken = data.RefreshToken;
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      logout();
    }
  } else {
    logout();
  }
}
