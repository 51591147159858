import { customDataService } from '@/services';

const state = {
  data: {
    InternalComment: [],
    BudgetEstimate: []
  }
};

const actions = {
  async get({ dispatch, commit }: any, { entityId, entityName }: any) {
    dispatch('application/setLoading', true, { root: true });

    await customDataService
      .get(entityId, entityName)
      .then(
        data => commit('getSuccess', data),
        error => commit('fail', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  async post({ dispatch, commit }: any, { entityId, entityName, name, payload }: any) {
    dispatch('application/setLoading', true, { root: true });

    return await customDataService
      .post(entityId, entityName, name, payload)
      .then(
        data => {
          dispatch('alert/success', `Uloženo.`, {
            root: true
          });
          commit('postSuccess', data);

          return data;
        },
        error => {
          commit('fail', error);
          dispatch('alert/error', 'Nepodařilo se uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  edit({ dispatch, commit }: any, { id, entityId, entityName, name, payload }: any) {
    dispatch('application/setLoading', true, { root: true });

    customDataService
      .edit(id, entityId, entityName, name, payload)
      .then(
        () => {
          //commit('editProject', form);
          dispatch('alert/success', `Uloženo.`, {
            root: true
          });
        },
        error => {
          commit('fail', error);
          dispatch('alert/error', 'Nepodařilo se uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  delete({ dispatch, commit }: any, { id, name }: any) {
    dispatch('application/setLoading', true, { root: true });

    customDataService
      .deleteData(id)
      .then(
        () => {
          commit('deleteSuccess', { id, name });
          dispatch('alert/success', `Odstraněno.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Nepodařilo se odstranit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getSuccess(state: any, data: any) {
    if (data) {
      state.data = data;
    } else {
      state.data = {
        InternalComment: [],
        BudgetEstimate: []
      };
    }
  },
  postSuccess(state: any, data: any) {
    if (state.data[data.Name]) {
      state.data[data.Name].push(data);
    } else {
      state.data[data.Name] = [data];
    }
  },
  editSuccess() {},
  deleteSuccess(state: any, data: any) {
    let filteredData = state.data[data.name].filter((item: any) => item.Id !== data.id);

    state.data[data.name] = filteredData;
  },
  fail(state: any, error: any) {
    state = { error };
  }
};

export const customData = {
  namespaced: true,
  state,
  actions,
  mutations
};
