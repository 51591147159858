import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import cs from 'vuetify/src/locale/cs';

// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';

const vuetify = new Vuetify();

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg'
});

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  lang: {
    locales: { cs },
    current: 'cs'
  }
});
