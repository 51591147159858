export function signatures(): any {
  return {
    doc: {
      signature: ['D0CF11E0A1B11AE1'],
      offset: 0,
      sizet: 8
    },
    docx: {
      signature: ['504B030414000600'],
      offset: 0,
      sizet: 8
    },
    xls: {
      signature: ['D0CF11E0A1B11AE1'],
      offset: 0,
      sizet: 8
    },
    xlsx: {
      signature: ['504B030414000600'],
      offset: 0,
      sizet: 8
    },
    jpeg: {
      signature: ['FFD8FFE0', 'FFD8FFE2', 'FFD8FFE3'],
      offset: 0,
      sizet: 4
    },
    jpg: {
      signature: ['FFD8FFE0', 'FFD8FFE1', 'FFD8FFE8'],
      offset: 0,
      sizet: 4
    },
    jfif: {
      signature: ['FFD8FFE0', 'FFD8FFE2', 'FFD8FFE3'],
      offset: 0,
      sizet: 4
    },
    pdf: {
      signature: ['25504446'],
      offset: 0,
      sizet: 4
    },
    gif: {
      signature: ['47494638'],
      offset: 0,
      sizet: 4
    },
    png: {
      signature: ['89504E47'],
      offset: 0,
      sizet: 4
    },
    txt: {
      offset: 0,
      sizet: 4
    },
    csv: {
      offset: 0,
      sizet: 4
    },
    json: {
      offset: 0,
      sizet: 4
    },
    msg: {
      signature: ['D0CF11E0A1B11AE1'],
      offset: 0,
      sizet: 8
    },
  };
}

export function getFileExtension(fileName: string): string {
  var matches = fileName && fileName.match(/\.([^.]+)$/);
  if (matches) {
    return matches[1].toLowerCase();
  }
  return '';
}
