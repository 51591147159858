export class TicketList {
  public cookiesKey: string = 'nittin_hd_search';
  public headers: any = [
    { text: 'Číslo tiketu', value: 'Id', width: '6%', sortable: false },
    { text: 'Název', value: 'Title', width: '20%', sortable: false },
    { text: 'Projekt', value: 'Project', width: '15%', sortable: false },
    { text: 'Datum vytvoření', value: 'CreatedOn', width: '10%', sortable: false },
    { text: 'Vytvořil', value: 'Author', width: '12%', sortable: false },
    { text: 'Termín Dokončení', value: 'Deadline', width: '10%', sortable: false },
    { text: 'Řešitel', value: 'Solver', width: '10%', sortable: false },
    { text: 'Priorita', value: 'Priority', width: '5%', align: 'center', sortable: false },
    { text: 'Stav', value: 'Status', width: '10%', align: 'center', sortable: false }
  ];

  public sortItems: any = [
    { text: 'Název A-Z', value: 'titleasc' },
    { text: 'Název Z-A', value: 'titledesc' },
    { text: 'Priorita vzestupně', value: 'priorityasc' },
    { text: 'Priorita vzestupně', value: 'prioritydesc' },
    { text: 'Nejnovější', value: 'newestdesc' },
    { text: 'Nejstarší', value: '' }
  ];

  getPriority(priority: string) {
    switch (priority) {
      case 'Low':
        return { icon: 'mdi-arrow-down', color: 'blue' };
      case 'Normal':
        return { icon: 'mdi-arrow-right', color: 'green' };
      case 'High':
        return { icon: 'mdi-arrow-up', color: 'red' };
      default:
        return { icon: 'mdi-arrow-right', color: 'green' };
    }
  }

  getStatus(status: string) {
    switch (status) {
      case 'InProgress':
        return { icon: 'mdi-progress-check', color: 'blue' };
      case 'New':
        return { icon: 'mdi-alert-decagram', color: 'orange' };
      case 'Finished':
        return { icon: 'mdi-check', color: 'green' };
      case 'Closed':
        return { icon: 'mdi-package-variant-closed', color: 'grey' };
      default:
        return { icon: 'mdi-alert-decagram', color: 'orange' };
    }
  }
}
