import { authHeader } from '@/helpers';
import { handleResponse } from './base.service';

export const userService = {
  getAll,
  getById,
  edit,
  addProject,
  removeProject,
  createUser
};

async function getAll(search: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await getAll(search);
  }
}

async function getById(id: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await getById(id);
  }
}

async function edit(form: any, userId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(form)
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users/${userId}/Edit`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await edit(form, userId);
  }
}

async function addProject(userId: number, projectId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users/${userId}/AssignProject/${projectId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await addProject(userId, projectId);
  }
}

async function removeProject(userId: number, projectId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users/${userId}/RemoveProject/${projectId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await removeProject(userId, projectId);
  }
}

async function createUser(form: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(form)
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Users`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await createUser(form);
  }
}
