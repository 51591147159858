








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Menu } from '../models/menu.model';

@Component({})
export default class MenuComponent extends Vue {
  account: any = this.$store.state.account;
  application: any = this.$store.state.application;
  menu: Menu = new Menu();

  toggleMenu() {
    (this as any).$store.dispatch('application/toggleMenu');
  }

  get menuItems() {
    return this.menu.items.filter(
      item =>
        item.logged === this.account.status.loggedIn &&
        !item.showInHeader &&
        (!item.onlyAdmin || (this.account.user.User && this.account.user.User.Role === 'Administrator')) &&
        (!item.onlyInternal || (this.account.user.User && !this.account.user.User.IsExternal) || (this.account.user.User && this.account.user.User.Role === 'Administrator'))
    );
  }
}
