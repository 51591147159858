import { getFileExtension, signatures } from '@/helpers';

export class ValidationService {
  public static validateLogin(username: any, password: any): void {
    this.checkEmtptyString(username);
    this.checkEmtptyString(password);
  }

  public static validateChangePassword(passwordModel: any): void {
    this.checkEmtptyString(passwordModel.oldPassword);
    this.checkEmtptyString(passwordModel.newPassword);
    this.checkEmtptyString(passwordModel.newPasswordConfirm);

    if (passwordModel.newPassword.isValid && passwordModel.newPasswordConfirm.isValid && passwordModel.newPassword.value !== passwordModel.newPasswordConfirm.value) {
      passwordModel.newPasswordConfirm.isValid = false;
      passwordModel.newPasswordConfirm.validationMessage = 'Hesla se neshodují.';
    }
  }

  public static validateNewTicket(ticketModel: any): void {
    this.checkEmtptyString(ticketModel.title);
    this.checkEmtptyString(ticketModel.project);
    this.checkEmtptyString(ticketModel.message);
  }

  public static checkEmtptyString(property: any) {
    if (property.value != '') {
      property.isValid = true;
    } else {
      property.isValid = false;
      property.validationMessage = 'Pole je povinné.';
    }
  }

  public static isFileValid(file: any, callback: any) {
    var fileExtension = getFileExtension(file.name);
    var fileSignatures = signatures();
    var fileSignature = fileSignatures[fileExtension];
    var isValid = false;
    if (fileSignature !== null && fileSignature !== undefined) {
      var slice = file.slice(fileSignature.offset, fileSignature.offset + fileSignature.sizet);
      var reader = new FileReader();

      reader.onloadend = () => {
        var buffer = reader.result,
          view = new DataView(buffer as any),
          signature;

        if (view.byteLength === 8) {
          signature = view.getUint32(0, false).toString(16) + view.getUint32(4, false).toString(16);
        } else {
          signature = view.getUint32(0, false).toString(16);
        }

        if (fileSignature.signature.includes(signature.toUpperCase())) {
          callback(true);
        } else {
          callback(false);
        }
      };

      reader.readAsArrayBuffer(slice);
    } else {
      callback(false);
    }
  }
}
