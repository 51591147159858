import { accountService, ValidationService } from '../services';
import { router } from '../helpers';

const user = JSON.parse(localStorage.getItem('user') || 'null');
const state = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

const actions = {
  login({ dispatch, commit }: any, { form }: any) {
    commit('loginRequest', form);

    accountService.login(form.username.value, form.password.value).then(
      user => {
        commit('loginSuccess', user);
        const returnUrl = localStorage.getItem('returnUrl');

        if (returnUrl != null) {
          localStorage.removeItem('returnUrl');
          router.push(`${returnUrl}`);
        } else {
          router.push('/');
        }

        dispatch('alert/success', `Byl jste úspěšně přihlášen do systému.`, {
          root: true
        });
      },
      error => {
        commit('loginFailure', error);
        form.username.errorMessage = `Kombinace uživatelského jména ${form.username.value} a hesla není platná.`;
        form.password.value = '';
        dispatch('alert/error', form.username.errorMessage, {
          root: true
        });
      }
    );
  },
  passwordReset({ dispatch, commit }: any, { form }: any) {
    accountService.passwordReset(form.username.value).then(
      user => {
        commit('passwordResetSuccess');
        dispatch('alert/success', `Heslo bylo úspěšně resetováno, zkontrolujte si, prosím, e-mailovou schránku pro nastavení nového hesla.`, {
          root: true
        });
      },
      error => {
        commit('passwordResetFailure', error);
        dispatch('alert/error', 'Nebylo možné vyresetovat heslo.', { root: true });
      }
    );
  },
  passwordNew({ dispatch, commit }: any, { form, resetToken, userKey }: any) {
    accountService.passwordNew(form.newPassword.value, resetToken, userKey).then(
      user => {
        commit('passwordResetSuccess');
        dispatch('alert/success', `Heslo bylo úspěšně nastaveno`, {
          root: true
        });

        router.push('/');
      },
      error => {
        commit('passwordResetFailure', error);
        form.newPassword.errorMessage = `Heslo nebylo možné změnit`;
        dispatch('alert/error', form.newPassword.errorMessage, { root: true });
      }
    );
  },
  passwordChange({ dispatch, commit }: any, { form }: any) {
    accountService.passwordChange(form, state.user.User.UserName).then(
      user => {
        commit('passwordChangeSuccess');
        dispatch('alert/success', `Heslo bylo úspěšně nastaveno`, {
          root: true
        });
      },
      error => {
        commit('passwordChangeFailure', error);
        form.oldPassword.errorMessage = 'Heslo nebylo možné změnit.';
        dispatch('alert/error', form.oldPassword.errorMessage, { root: true });
      }
    );
  },
  logout({ dispatch, commit }: any) {
    accountService.logout();
    commit('logout');
  }
};

const mutations = {
  loginRequest(state: any, form: any) {
    state.status = { loggingIn: true };
    state.user = form.username.value;
  },
  loginSuccess(state: any, user: any) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state: any) {
    state.status = { loggedIn: false };
    state.user = null;
  },
  passwordResetSuccess(state: any) {
    state.status = { loggedIn: false, passwordResetSuccess: true };
    state.user = null;
  },
  passwordResetFailure(state: any) {
    state.status = { loggedIn: false, passwordResetSuccess: true };
    state.user = null;
  },
  passwordChangeSuccess(state: any) {},
  passwordChangeFailure(state: any) {},
  logout(state: any) {
    state.status = { loggedIn: false };
    state.user = null;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
