






















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { router } from '../../helpers';
import { TicketDetail } from '../../models/ticket-detail.model';
// @ts-ignore
import { TiptapVuetify } from 'tiptap-vuetify';
import moment from 'moment';

@Component({
  components: {
    TiptapVuetify
  }
})
export default class TicketCreateComponent extends Vue {
  account: any = this.$store.state.account;
  tickets: any = this.$store.state.tickets;
  projects: any = this.$store.state.projects;
  application: any = this.$store.state.application;
  ticketDetail: TicketDetail = new TicketDetail();
  checked: any = false;
  

  async created() {
    await (this as any).$store.dispatch('projects/getAll');
  }

  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('projectId');

    if (code != null) {
      //if (this.projects.items.find((item: any) => item.Id === Number(code))) {
      this.ticketDetail.project.value = Number(code);
      //}
    }
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  handleCreateSubmit() {
    (this.$refs.form as any).validate();
    if (this.ticketDetail.valid) {
      const newTicket = this.ticketDetail;

      if (!this.ticketDetail.authorId) {
        newTicket.authorId = this.account.user.UserId;
      }

      (this as any).$store.dispatch('tickets/createTicket', { newTicket });
    }
  }

datePickerDialog: boolean = false;

get formattedDate(): string {
  if (this.ticketDetail.deadline) {
    return moment(String(this.ticketDetail.deadline)).format('DD.MM.YYYY');
  } else {
    return '';
  }
}


  handleCancelSubmit() {
    router.push(`/`);
  }

  customFilter(item: any, queryText: string, itemText: string) {
    const contactName = item.ContactName?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const name = item.Name?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const searchText = queryText
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return contactName.indexOf(searchText) > -1 || name?.indexOf(searchText) > -1;
  }

  get breadcrumbs() {
    return [
      {
        text: 'Tikety',
        disabled: false,
        to: '/'
      }
    ];
  }

  get projectId() {
    return this.ticketDetail.project.value;
  }

  @Watch('projectId')
  onProjectIdChanged(value: number) {
    if (value) {
      (this as any).$store.dispatch('projects/getById', value);
    }
  }
}
