import { authHeader } from '@/helpers';
import { handleResponse } from './base.service';

export const workRecordsService = {
  getAll,
  createWorkRecord,
  updateWorkRecord,
  getWorkTypes
};

async function getAll(search: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let filterQuery = '';

  if (search.dates) {
    filterQuery = `${filterQuery}&DateFrom=${search.dates[0]}`;
    filterQuery = `${filterQuery}&DateTo=${search.dates[1]}`;
  }

  if (search.projectId) {
    filterQuery = `${filterQuery}&ProjectId=${search.projectId}`;
  }

  if (search.ticketId) {
    filterQuery = `${filterQuery}&TicketId=${search.ticketId}`;
  }

  if (search.userId) {
    filterQuery = `${filterQuery}&UserId=${search.userId}`;
  }

  const response = await fetch(
    `${process.env.VUE_APP_API}/api/WorkRecords?PageSize=${search.pageRows}&PageNumber=${search.actualPage}&OrderBy=${search.orderBy}&OrderByAsc=${search.orderByAsc}${filterQuery}`,
    requestOptions as any
  );
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    if (handledResponse.headers && handledResponse.headers.find((header: any) => header.key === 'x-pagination')) {
      const pagination = JSON.parse(handledResponse.headers.find((header: any) => header.key === 'x-pagination').value);
      handledResponse.data.totalCount = pagination.TotalCount;
    }

    return handledResponse.data;
  } else {
    return await getAll(search);
  }
}

async function createWorkRecord(newWorkRecord: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      Date: newWorkRecord.date,
      HoursSpent: newWorkRecord.hoursSpent,
      IsBillable: newWorkRecord.isBillable,
      Note: newWorkRecord.note,
      ProjectId: newWorkRecord.projectId,
      TicketId: newWorkRecord.ticketId,
      UserId: newWorkRecord.userId,
      WorkTypeId: newWorkRecord.workTypeId
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${newWorkRecord.projectId}/workRecords`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await createWorkRecord(newWorkRecord);
  }
}

async function updateWorkRecord(newWorkRecord: any) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      Date: newWorkRecord.date,
      HoursSpent: newWorkRecord.hoursSpent,
      IsBillable: newWorkRecord.isBillable,
      Note: newWorkRecord.note,
      ProjectId: newWorkRecord.projectId,
      TicketId: newWorkRecord.ticketId,
      UserId: newWorkRecord.userId,
      WorkTypeId: newWorkRecord.workTypeId
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${newWorkRecord.projectId}/workRecords/${newWorkRecord.id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await updateWorkRecord(newWorkRecord);
  }
}

async function getWorkTypes(projectId: number): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/WorkTypes?ProjectId=${projectId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    if (handledResponse.headers && handledResponse.headers.find((header: any) => header.key === 'x-pagination')) {
      const pagination = JSON.parse(handledResponse.headers.find((header: any) => header.key === 'x-pagination').value);
      handledResponse.data.totalCount = pagination.TotalCount;
    }

    return handledResponse.data;
  } else {
    return await getWorkTypes(projectId);
  }
}
