import { authHeader } from '@/helpers';
import { accountService } from '.';
import { handleResponse } from './base.service';

export const contactService = {
  getAll,
  getById
};

async function getAll(): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Contacts`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    localStorage.setItem('contacts', JSON.stringify(handledResponse.data));

    return await handledResponse.data;
  } else {
    return await getAll();
  }
}

async function getById(id: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Contacts/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await getById(id);
  }
}
