import { contactService } from '@/services';

const state = {
  loading: false,
  items: [],
  detail: null,
  search: {
    includeClosed: false,
    onlyMine: false,
    pageRows: 10,
    actualPage: 1,
    orderBy: '',
    orderByAsc: false
  }
};

const actions = {
  getAll({ commit }: any, searchQuery: any) {
    commit('getRequest');

    contactService.getAll().then(
      contacts => commit('getAllSuccess', contacts),
      error => commit('getFailure', error)
    );
  },

  getById({ dispatch, commit }: any, id: any) {
    dispatch('application/setLoading', true, { root: true });

    contactService
      .getById(id)
      .then(
        contact => commit('getByIdSuccess', contact),
        error => commit('getFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getRequest(state: any) {
    state.loading = true;
  },
  getAllSuccess(state: any, contacts: any) {
    state.items = contacts;
  },
  getFailure(state: any, error: any) {
    state = { error };
  },
  getByIdSuccess(state: any, contact: any) {
    state.detail = contact;
  },
  clearContact(state: any) {
    state.detail = null;
  }
};

export const contacts = {
  namespaced: true,
  state,
  actions,
  mutations
};
