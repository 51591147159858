import { BaseProperty } from './baseProperty.model';
import {
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
  // @ts-ignore
} from 'tiptap-vuetify';

export class TicketDetail {
  public project: BaseProperty = new BaseProperty();
  public title: BaseProperty = new BaseProperty();
  public message: BaseProperty = new BaseProperty();
  public deadline: Date | null = null;
  public authorId: string = '';
  public files: any = [];
  public errorMessages: any = null;
  public valid: boolean = true;

  public extensions: any = [
    History,
    Blockquote,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3]
        }
      }
    ],
    Bold,
    HorizontalRule,
    Paragraph,
    HardBreak
  ];

  

  isImage(file: any): boolean {
    return /^image\//.test(file.type);
  }

  createUrl(file: any): string {
    return window.URL.createObjectURL(file);
  }

  getFileIcon(file: any) {
    var extension = file.Name.split('.').pop();

    switch (extension) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'jfif':
        return 'mdi-file-image-outline';
      case 'gif':
        return 'mdi-gif';
      case 'pdf':
        return 'mdi-file-pdf-outline';
      case 'xls':
      case 'xlsx':
        return 'mdi-file-excel-outline';
      case 'doc':
      case 'docx':
        return 'mdi-file-word-outline';
      case '.msg':
        return 'mdi-email-outline';
      default:
        return 'mdi-file-outline';
    }
  }
}
