import Vue from 'vue';
import App from './App.vue';
import { store } from './store';
import { router } from './helpers';
import VueI18n from 'vue-i18n';
import VueLodash from 'vue-lodash';
import random from 'lodash/random';
import map from 'lodash/map';
import './plugins/vuetify-currency-field.js';

import './scss/style.scss';

import moment from 'moment';

// @ts-ignore
import Editor from 'v-markdown-editor';
import 'v-markdown-editor/dist/v-markdown-editor.css';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import { translations } from './translations/translation';

Vue.use(VueI18n);
Vue.use(Editor);
Vue.use(VueLodash, { name: 'custom', lodash: { map, random } });

Vue.component('vue-recaptcha', VueReCaptcha);

import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';

Vue.use(VueCookies);

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'cs',
  messages: translations
});

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});

Vue.filter('formatOnlyDate', function (value: any) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.use(VueReCaptcha, { siteKey: '6LfTp74UAAAAAGMSdyCrSTrrDEY7lXxe4lfW1Bbx' });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
