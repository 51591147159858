export function authHeader() {
  var auth = authHeaderEmptyContentType();
  auth['Content-Type'] = 'application/json';

  return auth;
}

export function authHeaderEmptyContentType(): any {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user') || '');

  if (user && user.AccessToken.Token) {
    return {
      Authorization: 'Bearer ' + user.AccessToken.Token
    };
  } else {
    return {};
  }
}
