export const recaptchaService = {
  validate
};

function validate(token: string) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${process.env.VUE_APP_API}/api/captcha/verifyToken?token=${token}`, requestOptions as any).then(handleResponse);
}

function handleResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
