import { router } from '@/helpers';
import { userService } from '@/services';

const state = {
  loading: false,
  items: [],
  detail: null,
  search: {
    pageRows: 10,
    actualPage: 1,
    orderBy: '',
    orderByAsc: false
  }
};

const actions = {
  getAll({ commit }: any, searchQuery: any) {
    commit('getRequest');

    userService.getAll(searchQuery).then(
      users => commit('getAllSuccess', users),
      error => commit('getFailure', error)
    );
  },

  getById({ dispatch, commit }: any, id: any) {
    dispatch('application/setLoading', true, { root: true });

    userService
      .getById(id)
      .then(
        user => commit('getByIdSuccess', user),
        error => commit('getFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  edit({ dispatch, commit }: any, { form, userId }: any) {
    dispatch('application/setLoading', true, { root: true });

    userService
      .edit(form, userId)
      .then(
        () => {
          commit('editUser', form);
          dispatch('alert/success', `Uživatel byl upraven.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Uživatele se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  addProject({ dispatch, commit }: any, { userId, projectId }: any) {
    dispatch('application/setLoading', true, { root: true });

    userService
      .addProject(userId, projectId)
      .then(
        () => {
          commit('addProject', projectId);
          dispatch('alert/success', `Projekt byl přidán.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Projekt se nepodařilo přidat.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  removeProject({ dispatch, commit }: any, { userId, projectId }: any) {
    dispatch('application/setLoading', true, { root: true });

    userService
      .removeProject(userId, projectId)
      .then(
        () => {
          commit('removeProject', projectId);
          dispatch('alert/success', `Projekt byl odebrán.`, {
            root: true
          });
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Projekt se nepodařilo odebrat.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  create({ dispatch, commit }: any, { form }: any) {
    dispatch('application/setLoading', true, { root: true });

    userService
      .createUser(form)
      .then(
        () => {
          dispatch('alert/success', `Uživatel byl vytvořen.`, {
            root: true
          });

          router.push('/users');
        },
        error => {
          commit('getFailure', error);
          dispatch('alert/error', 'Uživatele se nepodařilo založit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getRequest(state: any) {
    state.loading = true;
  },
  getAllSuccess(state: any, users: any) {
    state.items = users;
  },
  getFailure(state: any, error: any) {
    state = { error };
  },
  getByIdSuccess(state: any, user: any) {
    state.detail = user;
  },
  editUser(state: any, user: any) {
    state.detail.LastName = user.LastName;
    state.detail.FirstName = user.FirstName;
    state.detail.UserName = user.UserName;
    state.detail.IsExternal = user.IsExternal;
    state.detail.Role = user.Role;
  },
  addProject(state: any, projectId: any) {
    state.detail.Projects.push({ Id: projectId });
  },
  removeProject(state: any, projectId: any) {
    state.detail.Projects = state.detail.Projects.filter((project: any) => project.Id !== projectId);
  },
  clearUser(state: any) {
    state.detail = null;
  }
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations
};
