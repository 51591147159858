const state = {
  menuVisible: false,
  ticketEdit: false,
  loading: false
};

const actions = {
  toggleMenu({ commit }: any) {
    commit('toggle');
  },
  toggleTicketEdit({ commit }: any) {
    commit('toggleTicketEdit');
  },
  setLoading({ commit }: any, loading: boolean) {
    commit('setLoading', loading);
  }
};

const mutations = {
  toggle(state: any) {
    state.menuVisible = !state.menuVisible;
  },
  toggleTicketEdit(state: any) {
    state.ticketEdit = !state.ticketEdit;
  },
  setLoading(state: any, loading: boolean) {
    state.loading = loading;
  }
};

export const application = {
  namespaced: true,
  state,
  actions,
  mutations
};
