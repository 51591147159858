var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex grow flex-wrap"},[_c('v-card-title',[_c('v-avatar',{attrs:{"tile":""}},[_c('v-icon',[_vm._v("mdi-view-list")])],1),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"light":""}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-divider',{staticClass:"mx-4 d-none d-md-block",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"align-self-center",attrs:{"depressed":"","small":"","color":"#e7501e","dark":""},on:{"click":_vm.createNew}},[_vm._v("Vytvořit nový tiket "),_c('v-icon',{attrs:{"x-small":"","right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-divider',{staticClass:"mx-4 d-none d-md-block",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-2","small":"","text":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","left":"","dark":""}},[_vm._v(" mdi-fast-forward ")]),_vm._v(" Rychlé filtry "),_c('v-icon',{attrs:{"x-small":"","right":"","dark":""}},[_vm._v(" mdi-arrow-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-checkbox',{staticClass:"ma-1 pa-0",attrs:{"label":"Pouze mé","value":"onlyMine","multiple":""},on:{"change":function($event){return _vm.quickFilter()}},model:{value:(_vm.tickets.search.quickFilters),callback:function ($$v) {_vm.$set(_vm.tickets.search, "quickFilters", $$v)},expression:"tickets.search.quickFilters"}})],1)],1)],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.ticketList.headers,"items":_vm.tickets.items,"items-per-page":20,"server-items-length":_vm.tickets.pagination.RowsCount,"loading":_vm.application.loading,"footer-props":{
        'items-per-page-options': [20],
        'disable-pagination': _vm.application.loading
      },"page":_vm.tickets.pagination.CurrentPage},on:{"update:page":_vm.onPage,"click:row":_vm.selectTicket},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"filterItem",attrs:{"cols":"12","md":"8"}},_vm._l((_vm.tickets.filters),function(filter){return _c('v-menu',{key:filter.id,attrs:{"offset-y":"","max-height":"400px","close-on-click":true,"close-on-content-click":false,"eager":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"small":"","text":"","depressed":"","color":"#003c5d","dark":"","disabled":_vm.application.loading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(filter.Title)+" ("+_vm._s(_vm.getCount(filter.Id))+") "),(attrs['aria-expanded'] !== 'true')?_c('v-icon',{attrs:{"x-small":"","right":"","dark":""}},[_vm._v(" mdi-arrow-down ")]):_c('v-icon',{attrs:{"x-small":"","right":"","dark":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-autocomplete',{attrs:{"label":filter.Title,"items":filter.Options,"item-text":"Label","item-value":"Id","multiple":"","chips":"","hide-no-data":"","mousedown":"","deletable-chips":"","clearable":"","filter":_vm.customFilter,"search-input":_vm.autocompleteSearch,"autofocus":""},on:{"change":function($event){return _vm.setFilterNew()},"update:searchInput":function($event){_vm.autocompleteSearch=$event},"update:search-input":function($event){_vm.autocompleteSearch=$event},"focus":function($event){return $event.target.click()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.remove(filter.Values, data.item.Id)}}},[(filter.Id === 'state')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('status.' + data.item.Label)))]):(filter.Id === 'priority')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('priority.' + data.item.Label)))]):(filter.Id === 'project')?_c('span',[_vm._v(" "+_vm._s(data.item.ParentName)+" - "+_vm._s(data.item.Label))]):_c('span',[_vm._v(" "+_vm._s(data.item.Label === '0' ? 'Nepřiřazeno' : data.item.Label))])])]}},{key:"item",fn:function(ref){
      var active = ref.active;
      var item = ref.item;
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(filter.Id === 'state')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('status.' + item.Label)))]):(filter.Id === 'priority')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('priority.' + item.Label)))]):(filter.Id === 'project')?_c('span',[_vm._v(" "+_vm._s(item.ParentName)+" - "+_vm._s(item.Label))]):_c('span',[_vm._v(" "+_vm._s(item.Label === '0' ? 'Nepřiřazeno' : item.Label))]),_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"white","color":"red","small":""}},[_vm._v(_vm._s(item.TotalCount))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(filter.Values),callback:function ($$v) {_vm.$set(filter, "Values", $$v)},expression:"filter.Values"}})],1)],1)],1)],1)}),1),_c('v-col',{staticClass:"d-flex px-2",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Vyhledávání","clearable":"","disabled":_vm.application.loading},on:{"click:clear":_vm.clearSearchText},model:{value:(_vm.tickets.search.searchQuery),callback:function ($$v) {_vm.$set(_vm.tickets.search, "searchQuery", $$v)},expression:"tickets.search.searchQuery"}})],1),_c('v-col',{staticClass:"d-flex filterItem px-2",attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"append-icon":"mdi-sort","items":_vm.ticketList.sortItems},on:{"change":function($event){return _vm.loadTickets()}},model:{value:(_vm.tickets.search.orderBy),callback:function ($$v) {_vm.$set(_vm.tickets.search, "orderBy", $$v)},expression:"tickets.search.orderBy"}})],1)],1)]},proxy:true},{key:"item.CreatedOn",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.CreatedOn))+" ")]}},{key:"item.Deadline",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatOnlyDate")(item.Deadline))+" ")]}},{key:"item.Solver",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.Solver || 'Nepřiřazeno')+" ")]}},{key:"item.Project",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.Project.Name)+" "),_c('small',[_vm._v("("+_vm._s(item.Contact.Name)+")")])]}},{key:"item.Priority",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.ticketList.getPriority(item.Priority).color}},on),[_vm._v(_vm._s(_vm.ticketList.getPriority(item.Priority).icon))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('priority.' + item.Priority)))])])]}},{key:"item.Status",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('status.' + item.Status)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }