import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account.module';
import { alert } from './alert.module';
import { tickets } from './ticket.module';
import { projects } from './project.module';
import { application } from './application.module';
import { users } from './user.module';
import { contacts } from './contact.module';
import { workRecords } from './work-records.module';
import { customData } from './custom-data.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    alert,
    tickets,
    projects,
    application,
    users,
    contacts,
    workRecords,
    customData
  }
});
