import { MenuItem } from '.';

export class Menu {
  public items: MenuItem[] = [
    new MenuItem('/', 'mdi-view-list', true, 'Tikety', false, false),
    new MenuItem('/create', 'mdi-message-text-outline', true, 'Vytvořit tiket', false, false),
    new MenuItem('/projects', 'mdi-chart-box-outline', true, 'Projekty', true, false),
    new MenuItem('/users', 'mdi-account-group', true, 'Uživatelé', true, false),
    new MenuItem('/account', 'mdi-account-box', true, 'Osobní', false, true),
    new MenuItem('/work-record', 'mdi-calendar-clock', true, 'Evidence práce', false, false, true),
    new MenuItem('/login', 'mdi-logout', true, 'Odhlásit', false, true),
    new MenuItem('/login', 'mdi-import', false, 'Přihlášení', false, false),
    new MenuItem('/passwordReset', 'mdi-lock-reset', false, 'Reset hesla', false, false)
  ];
}
