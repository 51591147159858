import { BaseProperty } from './baseProperty.model';

export class UserDetail {
  public firstName: BaseProperty = new BaseProperty();
  public lastName: BaseProperty = new BaseProperty();
  public userName: BaseProperty = new BaseProperty();
  public role: BaseProperty = new BaseProperty();
  public password: BaseProperty = new BaseProperty();
  public isExternal: boolean = false;
  public errorMessages: any = null;
  public valid: boolean = true;
}
