export class MenuItem {
  public url: string = '';
  public icon: string = '';
  public logged: boolean = true;
  public title: string = '';
  public onlyAdmin: boolean = false;
  public showInHeader: boolean = false;
  public onlyInternal: boolean = false;

  constructor(url: string, icon: string, logged: boolean, title: string, onlyAdmin: boolean, showInHeader: boolean, onlyInternal: boolean = false) {
    this.url = url;
    this.icon = icon;
    this.logged = logged;
    this.title = title;
    this.onlyAdmin = onlyAdmin;
    this.showInHeader = showInHeader;
    this.onlyInternal = onlyInternal;
  }
}
