


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { router } from '../../helpers';
import { ProjectList } from '../../models/project-list.model';

@Component({})
export default class ProjectListComponent extends Vue {
  account: any = this.$store.state.account;
  projects: any = this.$store.state.projects;
  application: any = this.$store.state.application;
  projectList: ProjectList = new ProjectList();

  created(): void {
    if (this.$cookies.isKey(this.projectList.cookiesKey)) {
      this.projects.search = this.$cookies.get(this.projectList.cookiesKey);
    }

    this.loadProjects();
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  selectProject(event: any): void {
    router.push(`/project/${event.Id}`);
  }
  createNew(): void {
    router.push(`/create-project`);
  }
  onPage(page: number): void {
    this.projects.search.actualPage = page;
    this.loadProjects();
  }
  onSort(field: any): void {
    this.projects.search.orderBy = field[0];
    this.loadProjects();
  }
  onSortDesc(desc: any): void {
    this.projects.search.orderByAsc = !desc[0];
    this.loadProjects();
  }
  onItemsPerPage(field: any): void {
    this.projects.search.pageRows = field === -1 ? this.projects.totalCount : field;
    this.loadProjects();
  }
  updateTickets(): void {
    this.projects.search.actualPage = 1;
    this.loadProjects();
  }
  loadProjects(): void {
    const searchQuery = this.projects.search;
    this.$cookies.set(this.projectList.cookiesKey, JSON.stringify(searchQuery), Infinity);

    (this as any).$store.dispatch('projects/getAll', searchQuery);
  }
  get breadcrumbs() {
    return [
      {
        text: 'Projekty',
        disabled: true
      }
    ];
  }
}
