import { ValidationService } from './../services/validation.service';
import { ticketService } from '@/services';
import { router } from '@/helpers';

const state = {
  search: {
    filterParameters: {},
    orderBy: '',
    page: 1,
    searchQuery: '',
    quickFilters: []
  },
  pagination: {
    currentPage: 1,
    pagesCount: 0,
    rowsCount: 0,
    rowsOnPage: 0
  },
  filters: [],
  loading: false,
  items: [],
  detail: null,
  totalCount: 0
};

const actions = {
  search({ dispatch, commit }: any, search: any) {
    dispatch('application/setLoading', true, { root: true });
    commit('saveSearchQuery', search);

    ticketService
      .search(search)
      .then(
        tickets => commit('getAllSuccess', tickets),
        error => commit('getAllFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  getById({ dispatch, commit }: any, id: any) {
    dispatch('application/setLoading', true, { root: true });

    ticketService
      .getById(id)
      .then(
        ticket => commit('getByIdSuccess', ticket),
        error => commit('getByIdFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },
  getHistory({ dispatch, commit }: any, id: any) {
    dispatch('application/setLoading', true, { root: true });

    ticketService
      .getHistory(id)
      .then(
        history => commit('getHistorySuccess', history),
        error => commit('getHistoryFailure', error)
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },
  saveMessage({ dispatch, commit }: any, { messageText }: any) {
    dispatch('application/setLoading', true, { root: true });

    let user = JSON.parse(localStorage.getItem('user') || '');

    ticketService
      .saveMessage(user.UserId, (state.detail! as any).Id, messageText)
      .then(
        () => {
          ticketService.getById((state.detail! as any).Id).then(ticket => {
            commit('saveTicketSuccess', ticket);
            dispatch('alert/success', `Zpráva byla uložena.`, {
              root: true
            });
          });
        },
        error => {
          commit('saveMessageFailure', error);
          dispatch('alert/error', 'Zprávu se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },
  saveTicket({ dispatch, commit }: any, { ticket }: any) {
    dispatch('application/setLoading', true, { root: true });

    ticketService
      .saveTicket(ticket)
      .then(
        () => {
          ticketService.getById(ticket.Id).then(ticket => {
            commit('saveTicketSuccess', ticket);
            dispatch('alert/success', `Změny na tiketu byly uloženy.`, {
              root: true
            });
          });
        },
        error => {
          commit('saveTicketFailure', error);
          dispatch('alert/error', 'Ticket se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },
  createTicket({ dispatch, commit }: any, { newTicket }: any) {
    dispatch('application/setLoading', true, { root: true });

    ticketService
      .createTicket(newTicket)
      .then(
        response => {
          commit('createTicketSuccess');
          dispatch('alert/success', `Tiket byl vytvořen.`, {
            root: true
          });
          router.push(`/ticket/${response?.data}`);
        },
        error => {
          commit('createTicketFailure', error);
          dispatch('alert/error', 'Ticket se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  uploadAttachments({ dispatch, commit }: any, { ticketId, files }: any) {
    dispatch('application/setLoading', true, { root: true });
    ticketService
      .uploadAttachments(ticketId, files)
      .then(
        attachments => {
          commit('saveAttachmentsSuccess', attachments.data);
          dispatch('alert/success', `Přílohy byly nahrány.`, {
            root: true
          });
        },
        error => {
          commit('saveAttachmentsFailure', error);
          dispatch('alert/error', 'Přílohy se nepodařilo uložit.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  },

  downloadAttachment({ dispatch, commit }: any, { ticketId, trustedFileName, fileName }: any) {
    ticketService.downloadAttachment(ticketId, trustedFileName, fileName);
  },

  openAttachment({ dispatch, commit }: any, { ticketId, trustedFileName, fileName }: any) {
    ticketService.openAttachment(ticketId, trustedFileName, fileName);
  },

  deleteAttachment({ dispatch, commit }: any, { ticketId, trustedFileName }: any) {
    dispatch('application/setLoading', true, { root: true });
    ticketService
      .deleteAttachment(ticketId, trustedFileName)
      .then(
        () => {
          commit('deleteAttachmentSuccess', trustedFileName);
          dispatch('alert/success', `Příloha byla odstraněna.`, {
            root: true
          });
        },
        error => {
          commit('deleteAttachmentFailure', error);
          dispatch('alert/error', 'Přílohu se nepodařilo smazat.', {
            root: true
          });
        }
      )
      .finally(() => dispatch('application/setLoading', false, { root: true }));
  }
};

const mutations = {
  getAllSuccess(state: any, response: any) {
    state.pagination = response.Pagination;
    state.filters = response.Filters;
    state.items = response.Tickets;

    state.filters.forEach((filter: any) => {
      filter.Values = [];
      filter.Options.filter((option: any) => option.Selected).forEach((option: any) => {
        filter.Values.push(option.Id);
      });
    });
  },
  getAllFailure(state: any, error: any) {
    state = { error };
  },
  getByIdSuccess(state: any, ticket: any) {
    state.detail = ticket;
  },
  getByIdFailure(state: any, error: any) {
    state = { error };
  },
  saveMessageSuccess(state: any) {},
  saveMessageFailure(state: any, error: any) {
    state = { error };
  },
  saveTicketSuccess(state: any, ticket: any) {
    state.detail = ticket;

    if (state.detail && state.detail.Deadline) {
      state.detail.Deadline = state.detail.Deadline.slice(0, 10);
    }
  },
  saveSearchQuery(state: any, searchQuery: any) {
    state.search = searchQuery;
  },
  saveTicketFailure(state: any, error: any) {
    state = { error };
  },
  createTicketSuccess(state: any) {},
  createTicketFailure(state: any, error: any) {
    state = { error };
  },
  saveAttachmentsSuccess(state: any, attachments: any) {
    state.detail.Attachments = attachments;
  },
  saveAttachmentsFailure(state: any, error: any) {
    state = { error };
  },
  deleteAttachmentSuccess(state: any, trustedFileName: string) {
    state.detail.Attachments = state.detail.Attachments.filter(function (el: any) {
      return el.TrustedFileName != trustedFileName;
    });
  },
  deleteAttachmentFailure(state: any, error: any) {
    state = { error };
  }
};

export const tickets = {
  namespaced: true,
  state,
  actions,
  mutations
};
