













































import { Component, Vue } from 'vue-property-decorator';
import { router } from '../../helpers';
import { ProjectDetail } from '../../models/project-detail.model';

@Component
export default class ProjectCreateComponent extends Vue {
  account: any = this.$store.state.account;
  application: any = this.$store.state.application;
  projectDetail: ProjectDetail = new ProjectDetail();
  contacts: any = this.$store.state.contacts;

  created() {
    (this as any).$store.dispatch('contacts/getAll');
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  submit() {
    (this.$refs.projectDetail as any).validate();
    //this.userDetail.customValidate();

    if (this.projectDetail.valid) {
      const form = {
        Name: this.projectDetail.name.value,
        ContactId: this.projectDetail.contactId.value
      };

      (this as any).$store.dispatch('projects/create', { form });
    }
  }

  handleCancelSubmit() {
    router.push(`/projects`);
  }

  get breadcrumbs() {
    return [
      {
        text: 'Projekty',
        disabled: false,
        to: '/projects'
      }
    ];
  }
}
