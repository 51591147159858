

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class WorkRecordTable extends Vue {
  application: any = this.$store.state.application;
  datePickerDialog: boolean = false;

  @Prop()
  ticket!: any;
  @Prop()
  dialog: any;

  save() {
    this.$emit('save');
  }

  cancel() {
    this.$emit('close');
  }

  get formattedDate(): string {
    if (this.ticket.Deadline) {
      return moment(String(this.ticket.Deadline)).format('DD.MM.YYYY');
    } else {
      return '';
    }
  }
}
