import { accountService } from './account.service';

export async function handleResponse(response: any) {
  if (response.ok) {
    const text = await response.text();
    const data = text && JSON.parse(text);

    const headers: any = [];

    for (const pair of response.headers.entries()) {
      headers.push({ key: pair[0], value: pair[1] });
    }
    return {
      data,
      headers
    };
  } else {
    if (response.status === 401 && response.headers.has('X-Token-Expired')) {
      await accountService.refreshToken();
      return { needsReload: true };
    }

    const error = response.statusText;
    return Promise.reject(error);
  }
}
