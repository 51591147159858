export const translations = {
  cs: {
    status: {
      InProgress: 'Realizace',
      Created: 'Založeno',
      Awaiting: 'Přijato',
      AwaitingForClient: 'Doplnění od zadavatele',
      Ready: 'Připraveno k realizaci',
      Done: 'Vyrobeno',
      ToApprove: 'Ke schválení',
      Accepted: 'Akceptováno / Dokončeno',
      Rejected: 'Neodsouhlaseno',
      Estimated: 'Naceněno',
      Cancel: 'Zrušeno / nerealizováno'
    },
    statusAction: {
      InProgress: 'Překlopit do realizace',
      Awaiting: 'Přijmout a učesat',
      AwaitingForClient: 'Získat podklady od zadavatele',
      Ready: 'Možno realizovat',
      Done: 'Už jsem dokončil',
      ToApprove: 'Možno dát ke schválení',
      Accepted: 'Akceptuji',
      Rejected: 'Neakceptuji',
      Estimated: 'Dát nacenění ke schválení',
      Cancel: 'Zrušit tiket'
    },
    priority: {
      Low: 'Nízká',
      Normal: 'Normální',
      High: 'Vysoká'
    }
  }
};
