































































import { Component, Vue } from 'vue-property-decorator';
import { recaptchaService } from '../../services';
import { Login } from '../../models/login.model';

@Component({})
export default class LoginComponent extends Vue {
  form: Login = new Login();
  showPassword: boolean = false;

  created(): void {
    (this as any).$store.dispatch('account/logout');
  }

  updated() {
    this.$recaptchaInstance?.showBadge();
  }

  resetCustomErrors(): void {
    this.form.username.errorMessage = '';
    this.form.password.errorMessage = '';
  }

  submit(): void {
    (this.$refs.form as any).validate();
    if (this.form.valid) {
      const form = this.form;
      this.$recaptcha('login').then(token => {
        recaptchaService.validate(token).then(response => {
          if (response.success) {
            (this as any).$store.dispatch('account/login', { form });
          }
        });
      });
    }
  }
}
